import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

// ----------------------------------------------------------------------

Logo.propTypes = {
  className: PropTypes.string,
};

function Logo({ height, className, ...other }) {
  return (
    <Box
      component="img"
      alt="logo2"
      src="/static/brand/logo.png"
      height={height || 80}
      className={className}
      {...other}
    />
  );
}

export default Logo;
